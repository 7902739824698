import classNames from 'classnames';
import { Currency } from 'enums/currency';
import { useNumberFormat } from 'hooks/useNumberFormat';
import { FunctionComponent, memo } from 'react';
import { useCurrencyState } from 'states/useCurrencyState';

interface CurrencyTextProps {
  price: number;
  className?: string;
  currency?: Currency;
}

const CurrencyText: FunctionComponent<CurrencyTextProps> = memo(
  function CurrencyText({ price, currency: propCurrency, className }) {
    const { currency: stateCurrency } = useCurrencyState();
    const { priceFormat } = useNumberFormat();

    const localizedPrice = priceFormat(price, propCurrency ?? stateCurrency);

    return <span className={classNames(className)}>{localizedPrice}</span>;
  }
);

export default CurrencyText;
