import { favoriteService } from 'services/FavoriteService';
import { useAuthCheck } from './useAuthCheck';
import { useEffect, useState } from 'react';
import { useFavoriteStore } from 'states/useFavorite.store';
import { analyticsService } from 'services/AnalyticsService';
import { SelectAndWishlistEventPayload } from 'types/analytics.type';

export function useFavorite(
  isFavorited: boolean,
  analyticsPayload: SelectAndWishlistEventPayload
) {
  const [isCurrentFavorited, setIsCurrentFavorited] = useState(isFavorited);
  const { checkLoggedIn } = useAuthCheck();
  const { setFavoritedVehicleIds, favoritedVehicleIds } = useFavoriteStore();

  useEffect(() => {
    setIsCurrentFavorited(isFavorited);
  }, [isFavorited]);

  const toggleFavorite = async (vehicleId: number) => {
    if (!checkLoggedIn()) {
      return;
    }
    try {
      setIsCurrentFavorited((f) => !f);
      const notChangedState = isCurrentFavorited;
      if (notChangedState) {
        await favoriteService.removeFavorite(vehicleId);
        const itemIdx = favoritedVehicleIds.findIndex((v) => v === vehicleId);
        if (~itemIdx) {
          const newFavoritedVehicles = [...favoritedVehicleIds];
          newFavoritedVehicles.splice(itemIdx, 1);
          setFavoritedVehicleIds(newFavoritedVehicles);
        }
      } else {
        await favoriteService.addFavorite(vehicleId);
        analyticsService.addToFavorite(analyticsPayload);
        setFavoritedVehicleIds([...favoritedVehicleIds, vehicleId]);
      }
    } catch {
      setIsCurrentFavorited((f) => !f);
    }
  };

  return {
    toggleFavorite,
    isCurrentFavorited,
  };
}
