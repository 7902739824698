import { useNumberFormat } from 'hooks/useNumberFormat';
import useTranslation from 'next-translate/useTranslation';
import { FunctionComponent } from 'react';
import { cn } from 'utils/cn';
import CdnSvg from './CdnSvg';

interface RatingStarProps {
  rating: number;
  hideCommentCount?: boolean;
  commentCount?: number;
  size?: number;
  small?: boolean;
}

const RatingStar: FunctionComponent<RatingStarProps> = ({
  rating,
  commentCount,
  hideCommentCount = false,
  size = 24,
  small = false,
}) => {
  const { t } = useTranslation('common');
  const { numberLocaleFormat } = useNumberFormat();

  const sizeClass = small ? 'text-xs' : 'text-sm md:text-base';
  const gapClass = small ? 'gap-1' : 'gap-2';

  return (
    <div className={cn('flex items-center', gapClass)}>
      <CdnSvg src="star-fill.svg" size={size} alt="rating-star" />
      {!rating || isNaN(rating) ? null : (
        <span className={sizeClass}>{numberLocaleFormat(rating)}</span>
      )}
      {hideCommentCount ? null : (
        <span className={cn('text-slate-500 md:inline', sizeClass)}>
          {`(${t('totalCommentCount', { count: commentCount })})`}
        </span>
      )}
    </div>
  );
};

export default RatingStar;
