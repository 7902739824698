import { AuthModalMode } from 'enums/authModalMode';
import { useAuthModalState } from 'states/useAuthModalState';
import { useUserState } from 'states/useUserState';

export function useAuthCheck() {
  const { isUserLoggedIn } = useUserState();
  const { setAuthMode, setShowAuthModal } = useAuthModalState();

  const checkLoggedIn = () => {
    if (!isUserLoggedIn) {
      setAuthMode(AuthModalMode.LOGIN);
      setShowAuthModal(true);
    }
    return isUserLoggedIn;
  };

  return {
    checkLoggedIn,
  };
}
