import { FunctionComponent } from 'react';
import CdnSvg from './CdnSvg';

interface FavouritedProps {
  size?: number;
  isCurrentFavorited: boolean;
  isPrimary?: boolean;
}

const Favourited: FunctionComponent<FavouritedProps> = ({
  isCurrentFavorited,
  size = 32,
  isPrimary = false,
}) => {
  return (
    <CdnSvg
      alt={isCurrentFavorited ? 'remove favourite' : 'favourite'}
      className="drop-shadow-md transition-[transform] duration-100 ease-in active:scale-90"
      src={
        isCurrentFavorited
          ? `favourite${isPrimary ? '-primary' : ''}-fill.svg`
          : `favourite${isPrimary ? '-primary' : ''}.svg`
      }
      size={size}
    />
  );
};

export default Favourited;
