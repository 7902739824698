import { FunctionComponent } from 'react';

interface SeoContentProps {
  content: string;
}

const SeoContent: FunctionComponent<SeoContentProps> = ({ content }) => {
  return (
    <div>
      <div
        className="seo-content prose prose-slate relative mx-auto w-full max-w-none"
        dangerouslySetInnerHTML={{ __html: content as string }}
      />
    </div>
  );
};

export default SeoContent;
