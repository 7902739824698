import Head from 'next/head';
import Script from 'next/script';
import { FunctionComponent, ReactNode } from 'react';
import { Helmet } from 'react-helmet';
import { HelmetSettings } from 'types/seo.type';
import SeoContent from './SeoContent';

interface SeoWrapperProps {
  children: ReactNode | ReactNode[];
  helmetSettings: HelmetSettings;
  content?: string;
}

const SeoWrapper: FunctionComponent<SeoWrapperProps> = ({
  children,
  helmetSettings,
  content,
}) => {
  const { title, script, ...restHelmetSettings } = helmetSettings;

  return (
    <>
      <Helmet {...restHelmetSettings} />
      <Head>
        <title>{title}</title>
      </Head>
      <Script
        id="organization-schema"
        type="application/ld+json"
        strategy="beforeInteractive"
        dangerouslySetInnerHTML={{
          __html: `{"@context":"https://schema.org","@type":"Corporation","name":"Teknevia","alternateName":"Kiralık Tekne ve Yat Kiralama","url":"https://www.teknevia.com/","logo":"https://cdn.teknevia.com/storefront/common/teknevia-logo.png","contactPoint":{"@type":"ContactPoint","telephone":"+90 552 858 23 70","contactType":"customer service","areaServed":"TR","availableLanguage":["en","Turkish"]},"address":{"@type":"PostalAddress","streetAddress":"Mimar Sinan Mah. Küçük Sarmaşık Sok. No:11/3","addressLocality":"Istanbul, Turkey","addressCountry":"TR","postalCode":"34674"},"sameAs":["https://www.facebook.com/people/Teknevia/100075736485964","https://www.instagram.com/teknevia/","https://www.youtube.com/@teknevia","https://www.linkedin.com/company/tekneviateknoloji/","https://tr.pinterest.com/teknevia/"]}`,
        }}
      />
      {script ? (
        <Script
          id="schema"
          type="application/ld+json"
          strategy="beforeInteractive"
          dangerouslySetInnerHTML={{
            __html: script,
          }}
        />
      ) : null}
      {children}
      {content ? <SeoContent content={content} /> : null}
    </>
  );
};

export default SeoWrapper;
