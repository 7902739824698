import { Currency } from 'enums/currency';
import { useLocale } from 'hooks/useLocale';
import { useCallback } from 'react';

export function useNumberFormat() {
  const { locale } = useLocale();

  const numberLocaleFormat = useCallback(
    (
      n: number,
      options: Intl.NumberFormatOptions | undefined = {
        minimumFractionDigits: 1,
        maximumFractionDigits: 2,
      }
    ): string => {
      return new Intl.NumberFormat(locale, options).format(n);
    },
    [locale]
  );

  const priceFormat = useCallback(
    (price: number, currency: Currency) => {
      return new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: currency,
      }).format(price);
    },
    [locale]
  );

  return {
    numberLocaleFormat,
    priceFormat,
  };
}
