import classNames from 'classnames';
import BBadge from 'components/common/BBadge';
import BImage from 'components/common/BImage';
import CurrencyText from 'components/common/CurrencyText';
import { BusinessModel } from 'enums/businessModel';
import { Currency } from 'enums/currency';
import { Locale } from 'enums/locale';
import { VehiclePriceUnit } from 'enums/vehiclePriceUnit';
import { useBreakpoint } from 'hooks/useBreakpoints';
import { useFavorite } from 'hooks/useFavorite';
import useTranslation from 'next-translate/useTranslation';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { FunctionComponent, useMemo } from 'react';
import { analyticsService } from 'services/AnalyticsService';
import { dateService } from 'services/DateService';
import { linkService } from 'services/LinkService';
import { useFavoriteStore } from 'states/useFavorite.store';
import styles from 'styles/VehicleCard.module.css';
import { Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import { SelectAndWishlistEventPayload } from 'types/analytics.type';
import { VehicleBasedDemandState } from 'types/demand.type';
import { SearchDemandDTO } from 'types/search.type';
import { VehicleSummary } from 'types/vehicle.type';
import vehicleNameCreator from 'utils/vehicleNameCreator';
import qs from 'qs';
import { useSearchFromQuery } from 'hooks/useSearchFromQuery';
import { useUserState } from 'states/useUserState';
import Favourited from 'components/common/Favourited';
import RatingStar from 'components/common/RatingStar';

interface VehicleCardProps extends VehicleSummary {
  isFirstImagePrioritized?: boolean;
  containerClass?: string;
  location?: string;
}

const VehicleCard: FunctionComponent<VehicleCardProps> = ({
  containerClass,
  isFirstImagePrioritized = false,
  location,
  ...vehicle
}) => {
  const { t } = useTranslation('common');
  const { locale, asPath } = useRouter();
  const { isMobile } = useBreakpoint();
  const { isUserLoggedIn } = useUserState();
  const { getVehicleFavorited } = useFavoriteStore();
  const { getStateFromQuery, getLocationFromRoute } = useSearchFromQuery();

  const analyticsPayload: SelectAndWishlistEventPayload = useMemo(
    () => ({
      asPath,
      vehiclePriceUnit: vehicle.price?.unit || VehiclePriceUnit.HOUR,
      currency: vehicle.price?.currency ?? Currency.TRY,
      hourlyPrice: vehicle.price?.amount ?? 0,
      region: vehicle.regionTR,
      vehicleType: vehicle.type,
      vehicleId: vehicle.vehicleId,
      vehicleName: vehicleNameCreator(
        vehicle.brand,
        vehicle.length,
        t(`boatTypes.${vehicle.type}`),
        t
      ),
    }),
    [asPath, t, vehicle]
  );

  const isFavorited =
    isUserLoggedIn &&
    (vehicle.isFavorited || getVehicleFavorited(vehicle.vehicleId));
  const { isCurrentFavorited, toggleFavorite } = useFavorite(
    isFavorited,
    analyticsPayload
  );

  const constructHourlyQueryParams = (params: SearchDemandDTO) => {
    return {
      date: params.startTime
        ? (params.startTime as Date)?.getTime()
        : undefined,
      duration:
        params.startTime && params.endTime
          ? dateService.getHourDuration(
              params.startTime as Date,
              params.endTime as Date
            )
          : undefined,
      numberOfPeople: params.numberOfPeople,
      fromLocation: params.fromLocation,
    } as Partial<VehicleBasedDemandState>;
  };

  const constructDWQueryParams = (params: SearchDemandDTO) => {
    return {
      startDate: (params.startTime as Date)?.getTime(),
      endDate: (params.endTime as Date)?.getTime(),
      numberOfPeople: params.numberOfPeople,
      fromLocation: params.fromLocation,
    };
  };

  const contructQueryParams = () => {
    const mappedQueries = getStateFromQuery();

    let startTime: Date | undefined = undefined;
    let endTime: Date | undefined = undefined;

    if (vehicle.nearestAvailableTime?.startTime) {
      startTime = dateService.formatServerDateToLocalDate(
        vehicle.nearestAvailableTime.startTime
      );
    } else if (mappedQueries.startTime) {
      startTime =
        typeof window !== 'undefined'
          ? dateService.formatUtcDateToLocalTz(mappedQueries.startTime)
          : dateService.setTimeZone(mappedQueries.startTime, mappedQueries.tz);
    }
    if (vehicle.nearestAvailableTime?.endTime) {
      endTime = dateService.formatServerDateToLocalDate(
        vehicle.nearestAvailableTime.endTime
      );
    } else if (mappedQueries.endTime) {
      endTime =
        typeof window !== 'undefined'
          ? dateService.formatUtcDateToLocalTz(mappedQueries.endTime)
          : dateService.setTimeZone(mappedQueries.endTime, mappedQueries.tz);
    }

    const mappedDto: SearchDemandDTO = {
      ...mappedQueries,
      startTime,
      endTime,
      fromLocation: getLocationFromRoute()?.name,
    } as SearchDemandDTO;

    let mappedSearchState;
    if (vehicle.businessModel === BusinessModel.HOURLY) {
      mappedSearchState = constructHourlyQueryParams(mappedDto);
    } else {
      mappedSearchState = constructDWQueryParams(mappedDto);
    }
    return qs.stringify(mappedSearchState);
  };

  const linkConstructor = () => {
    const queryParams = contructQueryParams();
    return linkService.getVehicleLink(
      vehicle.type,
      vehicle.vehicleId,
      locale as Locale,
      queryParams
    );
  };

  const sendAnalytics = () => {
    analyticsService.vehicleCardClick(analyticsPayload);
  };

  const nearestAvailableTime = useMemo(() => {
    if (!vehicle.nearestAvailableTime) return null;
    else {
      const startTime = dateService.formatServerDateToLocalDate(
        vehicle.nearestAvailableTime.startTime
      );
      const endTime = dateService.formatServerDateToLocalDate(
        vehicle.nearestAvailableTime.endTime
      );
      if (vehicle.businessModel === BusinessModel.HOURLY) {
        return dateService.getReadableShortRangeDateTime(
          startTime,
          startTime.getHours(),
          endTime.getHours()
        );
      }
      if (vehicle.businessModel === BusinessModel.DAILY_WEEKLY) {
        return dateService.getReadableShortRangeDate(startTime, endTime);
      }
    }
  }, [vehicle.nearestAvailableTime, vehicle.businessModel]);

  return (
    <div className="relative">
      <div
        className={classNames(
          'translate-z-0 absolute top-1.5 z-10 flex w-full items-center px-1.5',
          vehicle.immediatelyReservable ? 'justify-between' : 'justify-end'
        )}
      >
        {vehicle.immediatelyReservable ? (
          <BBadge
            className="shadow-sm shadow-slate-500"
            variant="info"
            contrasted
          >
            {t('vehicleImmediatelyReservable')}
          </BBadge>
        ) : null}
        <div
          className="transform cursor-pointer rounded-full duration-200 ease-in"
          onClick={() => toggleFavorite(vehicle.vehicleId)}
          tabIndex={-1}
          role="button"
        >
          <Favourited isCurrentFavorited={isCurrentFavorited} />
        </div>
      </div>
      <Link href={linkConstructor()}>
        <a
          className={classNames(styles.container, containerClass)}
          target={isMobile ? '' : '_blank'}
        >
          <div
            className={styles.image}
            onClick={sendAnalytics}
            role="presentation"
          >
            <Swiper
              modules={[Pagination]}
              pagination={{
                clickable: true,
                dynamicBullets: true,
                dynamicMainBullets: 5,
              }}
              spaceBetween={0}
              slidesPerView={1}
            >
              {vehicle.images.map((image, idx) => (
                <SwiperSlide key={`${image}-${idx}`}>
                  <BImage
                    src={image}
                    height={240}
                    width={300}
                    alt={vehicle.type}
                    className="object-cover"
                    priority={isFirstImagePrioritized && idx === 0}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div>
            <div className="mb-1 flex items-center justify-between">
              <div className="text-sm">
                <span className="text-base font-medium">
                  {`${vehicle.originHarbor}, ${vehicle.region}`}
                  {location && location !== vehicle.originHarbor
                    ? ` - (${t('departuredFrom', { location })})`
                    : null}
                </span>
              </div>
              <div className="flex shrink-0 items-center">
                <RatingStar
                  commentCount={vehicle.commentCount}
                  rating={vehicle.rating}
                  size={16}
                  small
                />
              </div>
            </div>
            <span className="mb-1 block text-sm text-slate-500">
              {t(`boatTypes.${vehicle.type}`)}
            </span>
            <span className="mb-2 block text-sm text-slate-500">
              {t('capacity')}:{' '}
              {t('numberOfPeople', {
                numberOfPeople: vehicle.fullCapacity,
              })}
            </span>
            {nearestAvailableTime ? (
              <span className="mb-2 block text-sm text-slate-500">
                {nearestAvailableTime}
              </span>
            ) : null}
            {!vehicle.price ? null : (
              <div>
                <CurrencyText
                  className="text-lg"
                  price={vehicle.price.amount}
                  currency={vehicle.price.currency}
                />{' '}
                <span className="text-lg text-slate-500">
                  {t(`businessModelPriceBreakpoints.${vehicle.price.unit}`)}
                </span>
              </div>
            )}
          </div>
        </a>
      </Link>
    </div>
  );
};

export default VehicleCard;
