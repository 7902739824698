import { FunctionComponent, ReactNode } from 'react';
import { Variants } from 'types/bosphorus.type';
import { cn } from 'utils/cn';

interface BBadgeProps {
  variant?: Variants;
  children: ReactNode;
  className?: string;
  contrasted?: boolean;
}

const BBadge: FunctionComponent<BBadgeProps> = ({
  variant = 'primary',
  children,
  className,
  contrasted = false,
}) => {
  const classesByVariant: Record<Variants, string> = {
    primary: 'text-primary-900 bg-primary-100',
    secondary: 'text-slate-900 bg-slate-100',
    success: 'text-success-900 bg-success-100',
    danger: 'text-danger-900 bg-danger-100',
    warning: 'text-warning-900 bg-warning-100',
    info: 'text-info-900 bg-info-100',
  };

  const contrastedClasses: Record<Variants, string> = {
    primary: 'bg-primary-600 text-white',
    secondary: 'bg-slate-600 text-white',
    success: 'bg-success-600 text-white',
    danger: 'bg-danger-600 text-white',
    warning: 'bg-warning-600 text-white',
    info: 'bg-info-600 text-white',
  };

  return (
    <span
      className={cn(
        'inline-block rounded-full px-3 py-1.5 text-xs md:text-sm',
        {
          [classesByVariant[variant]]: !contrasted,
          [contrastedClasses[variant]]: contrasted,
        },
        className
      )}
    >
      {children}
    </span>
  );
};

export default BBadge;
